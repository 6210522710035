<template>
  <v-row :class="{'fill-height' : isLargeScreen}" justify="center" justify-md="start" no-gutters>
    <v-col cols="10" md="12">
      <v-row :class="{'fill-height' : isLargeScreen}" align="center" justify="center" justify-md="start"
             no-gutters>
        <v-col class="fill-height" cols="12" md="4" sm="6">
          <kurcc-auth-intro/>
        </v-col>
        <v-col cols="12" md="4" offset-md="2">
          <validation-observer ref="observer" v-slot="{ invalid, handleSubmit }">
            <v-form @submit.prevent="handleSubmit(login)">
              <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.auth.email')"
                                   rules="required|email">
                <v-text-field v-model="email" :error-messages="errors"
                              :label="$vuetify.lang.t('$vuetify.pages.auth.email')"
                              autofocus outlined
                              prepend-inner-icon="mdi-email-outline"/>
              </validation-provider>
              <validation-provider v-slot="{ errors }" :name="$vuetify.lang.t('$vuetify.pages.auth.password')"
                                   rules="required">
                <v-text-field v-model="password" :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                              :error-messages="errors" :label="$vuetify.lang.t('$vuetify.pages.auth.password')"
                              :type="showPassword ? 'text' : 'password'" outlined
                              prepend-inner-icon="mdi-lock"
                              @click:append="showPassword = !showPassword"/>
              </validation-provider>
              <v-row align="center" class="mb-2" justify="center" no-gutters>
                <v-checkbox v-model="staySignedIn">
                  <template v-slot:label>
                    <span class="text-uppercase">
                      {{ $vuetify.lang.t('$vuetify.pages.auth.rememberMe') }}
                    </span>
                  </template>
                </v-checkbox>
              </v-row>
              <v-btn :disabled="invalid" :loading="loggingIn" color="secondary" tile type="submit" width="100%">
                {{ $vuetify.lang.t('$vuetify.pages.auth.login') }}
              </v-btn>
              <v-btn :to="{name: 'forgot-password'}" class="my-5" color="secondary" text>
                {{ $vuetify.lang.t('$vuetify.pages.auth.forgotPassword') }}
              </v-btn>
              <v-row align="center" class="mb-10" justify="center" no-gutters>
                {{ $vuetify.lang.t('$vuetify.pages.auth.noAccount') }}
                <v-btn :to="{name:'register'}" color="secondary" text>
                  {{ $vuetify.lang.t('$vuetify.pages.auth.registerNow') }}
                </v-btn>
              </v-row>
              <v-row align="center" class="mb-10" justify="center" no-gutters>
                <v-col cols="12">
                  <v-btn color="grey" small text>
                    {{ $vuetify.lang.t('$vuetify.pages.auth.registrationSteps') }}
                  </v-btn>
                  <v-divider class="my-2"/>
                </v-col>
                <v-col cols="12">
                  <v-btn color="grey" small text>
                    {{ $vuetify.lang.t('$vuetify.pages.auth.faqs') }}
                  </v-btn>
                  <v-divider class="my-2"/>
                </v-col>
                <v-col class="my-2" cols="12">
                  <v-btn :to="{name: 'main'}" color="grey" small text>
                    {{ $vuetify.lang.t('$vuetify.pages.auth.homePage') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </validation-observer>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'KurccLoginPage',
  components: {
    ValidationObserver,
    KurccAuthIntro: () => import('@/modules/auth/components/KurccAuthIntro')
  },
  data () {
    return {
      email: null,
      password: null,
      showPassword: false,
      staySignedIn: false,
      loggingIn: false
    }
  },
  computed: {
    isLargeScreen () {
      return this.$vuetify.breakpoint.mdAndUp
    }
  },
  methods: {
    login () {
      this.loggingIn = true
      this.$store.dispatch('login', {
        email: this.email,
        password: this.password,
        staySignedIn: this.staySignedIn
      }).finally(() => {
        this.loggingIn = false
      })
    }
  }
}
</script>
